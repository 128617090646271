import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import PageTitle from "../components/page-title"
import { Row } from "../components/grid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import SocialButton from "../components/social-button"
import SectionBreak from "../components/section-break"
import BrandButton from "../components/brand-button"

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <Img
      fluid={data.byseaLogo.childImageSharp.fluid}
      className="w-50 h-auto mx-auto d-block wp-post-image"
    />
    <PageTitle title="We are By Sea" />
    <div className="text-center">
      <p className="col-12 col-md-8 offset-md-2 mb-5">
        Brett McKinney and Dan Walsh together make up the electro/post-rock
        music duo "By Sea".
      </p>

      <div>
        <BrandButton
          brand="bandcamp"
          href="https://bysea.bandcamp.com/"
          label="Check us out on Bandcamp"
          outline="false"
          size="lg"
        />
        <p>Or stream us now on:</p>
        <BrandButton
          brand="apple"
          href="https://music.apple.com/au/artist/by-sea/1501172909"
        />
        <BrandButton
          brand="spotify"
          href="https://open.spotify.com/artist/6erwBBSrBtzsG3ak1AJu9l"
        />
        <BrandButton
          brand="youtubemusic"
          href="https://music.youtube.com/channel/UCtJvQ8rMKdLrw1RLsiBEBcw"
        />
        <BrandButton
          brand="tidal"
          href="https://listen.tidal.com/artist/18549977"
        />
      </div>
    </div>
    <SectionBreak />
    <h2>"Calibrate" single out now</h2>
    <Row>
      <div className="col-12 col-md-8 offset-md-2 text-center">
        <p>
          Our fourth single "<Link to="/releases/calibrate">Calibrate</Link>" is
          out now on all major streaming services.
        </p>
      </div>
      <div className="col-12 col-md-8 offset-md-2">
        <Link
          to="/releases/calibrate"
          title="Check out our latest track 'Calibrate'"
        >
          <Img
            fluid={data.runCover.childImageSharp.fluid}
            className="w-75 h-auto mx-auto d-block wp-post-image rounded shadow"
          />
        </Link>
      </div>
      <div className="col-12 mt-5 text-center">
        <Link to="/releases" className="btn btn-outline-primary">
          See our other releases
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Link>
      </div>
    </Row>
    <SectionBreak />
    <h2>Follow us on social</h2>
    <div className="d-flex justify-content-center">
      <SocialButton network="facebook" />
      <SocialButton network="twitter" />
      <SocialButton network="youtube" />
      <SocialButton network="instagram" />
    </div>
    {/* <SectionBreak />
    <h2>See us on Instagram</h2>
    <SectionBreak />
    <h2>Follow us on Facebook</h2> */}
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    byseaLogo: file(relativePath: { eq: "bysea-watermark.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    runCover: file(relativePath: { eq: "cover-calibrate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
