import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

const SocialButton = ({ network }) => {
  const networks = {
    facebook: {
      name: "Facebook",
      icon: faFacebook,
      href: "https://www.facebook.com/bysea.band",
    },
    twitter: {
      name: "Twitter",
      icon: faTwitter,
      href: "https://twitter.com/bysea_band",
    },
    youtube: {
      name: "YouTube",
      icon: faYoutube,
      href: "https://www.youtube.com/@bysea",
    },
    instagram: {
      name: "Instagram",
      icon: faInstagram,
      href: "https://www.instagram.com/bysea_band/",
    },
  }
  return (
    <a
      className="btn btn-outline-primary btn-lg mx-2 d-inline-flex p-2"
      href={networks[network].href}
      target="_blank"
      rel="noopener noreferrer"
      title={`Visit us on ${networks[network].name}`}
    >
      <FontAwesomeIcon icon={networks[network].icon} className="" />
    </a>
  )
}

export default SocialButton
